<template>
  <div>
    <loading
      height="100px"
      width="100px"
      style="
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 9999;
      "
      v-if="fetching"
    ></loading>
    <full-calendar ref="fullCalendar" :options="config"></full-calendar>
  </div>
</template>

<script>
import Loading from "../../components/loaders/Loading";
import FullCalendar from "@fullcalendar/vue";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";

export default {
  data() {
    return {
      resources: [],
      roomFetchUrl: "/rooms/fetch",
      selectedRetreat: localStorage.getItem("selectedRetreat") ?? "uk",
      fetching: false
    };
  },
  computed: {
    config() {
      return {
        ...this.calendarOptions,
        ...this.eventHandlers
      };
    },
    calendarOptions() {
      return {
        schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
        plugins: [resourceTimelinePlugin, interactionPlugin],
        initialView: "resourceTimelineSixMonths",
        events: (info, callback) => {
          this.fetching = true;
          let start = moment(info.start.valueOf()).format("YYYY-MM-DD");
          let end = moment(info.end.valueOf()).format("YYYY-MM-DD");
          this.$axios
            .get(
              process.env.VUE_APP_API_URL +
                "/bookings/calendar-fetch?start=" +
                start +
                "&end=" +
                end +
                "&retreat=" +
                this.selectedRetreat
            )
            .then(({ data }) => {
              this.fetching = false;
              callback(data);
            });
        },
        resources: (info, callback) => {
          this.$axios
            .get(
              process.env.VUE_APP_API_URL +
                "/rooms/fetch?retreat=" +
                this.selectedRetreat
            )
            .then(({ data }) => {
              data.forEach(room => {
                if (room.retreat == "uk") {
                  room.title = room.name + " (" + room.type + ")";
                } else {
                  room.title =
                    room.name + " (" + room.type + ") / " + room.floor;
                }
              });
              callback(data);
              // callback(data);
            });
        },
        // resources: this.resources,
        resourceAreaWidth: "16%",
        // resourceGroupField: "retreat",

        resourceAreaColumns: [
          {
            field: "title",
            headerContent:
              this.selectedRetreat == "uk" ? "UK Rooms" : "Malta Rooms"
          }
        ],
        height: "94vh",
        views: {
          resourceTimelineOneMonths: {
            type: "resourceTimeline",
            duration: { months: 1 },
            buttonText: "1 month"
          },
          resourceTimelineThreeMonths: {
            type: "resourceTimeline",
            duration: { months: 3 },
            buttonText: "3 months"
          },
          resourceTimelineSixMonths: {
            type: "resourceTimeline",
            duration: { months: 6 },
            buttonText: "6 months"
          },
          resourceTimelineTwelveMonths: {
            type: "resourceTimeline",
            duration: { months: 12 },
            buttonText: "12 months"
          }
        },
        headerToolbar: {
          start: "title",
          center:
            "filterUk,filterMalta resourceTimelineOneMonths,resourceTimelineThreeMonths,resourceTimelineSixMonths,resourceTimelineTwelveMonths",
          end: "today prev,next close"
        },
        loading: isLoading => {
          return (this.fetching = isLoading);
        },
        customButtons: {
          filterBothRetreats: {
            text: "Both Retreats",
            click: () => {
              this.$nextTick(() => {
                this.roomFetchUrl = "/rooms/fetch";
                this.selectedRetreat = "";
                let calendarApi = this.$refs.fullCalendar.getApi();
                calendarApi.refetchResources();
                calendarApi.refetchEvents();
              });
            }
          },
          filterMalta: {
            text: "Malta",
            click: () => {
              this.$nextTick(() => {
                this.roomFetchUrl = "/rooms/fetch?retreat=malta";

                this.selectedRetreat = "malta";
                localStorage.setItem("selectedRetreat", this.selectedRetreat);

                let calendarApi = this.$refs.fullCalendar.getApi();
                calendarApi.refetchResources();
                calendarApi.refetchEvents();
              });
            }
          },
          filterUk: {
            text: "UK",
            click: () => {
              this.$nextTick(() => {
                this.roomFetchUrl = "/rooms/fetch?retreat=uk";

                this.selectedRetreat = "uk";
                localStorage.setItem("selectedRetreat", this.selectedRetreat);

                let calendarApi = this.$refs.fullCalendar.getApi();
                calendarApi.refetchResources();
                calendarApi.refetchEvents();
              });
            }
          },
          close: {
            text: "<i class='far fa-times'></i>",
            click: () => {
              this.$router.back();
            }
          }
        }
      };
    },
    eventHandlers() {
      return {
        dateClick: this.onDateClick,
        eventClick: this.onEventClick,
        eventDrop: this.onEventDrop,
        eventResize: this.onEventDrop,
        select: this.onDateSelect
      };
    }
  },
  methods: {
    onDateClick(payload) {
      // const title = prompt("Please enter a new title for your event");
      // if (!title) {
      //   return;
      // }
      // const id = (this.events.length + 1) * 10;
      // const { start, end, date, allDay } = payload;
      // return this.createEvent({
      //   id,
      //   title,
      //   date,
      //   start,
      //   end,
      //   allDay,
      // });
    },
    onDateSelect(payload) {
      return this.onDateClick(payload);
    },
    onEventClick({ event }) {
      this.$router.push({
        path: "/clients/" + event.extendedProps.clients[0].id,
        hash: "retreat-bookings",
        query: {
          "retreat-booking": event.extendedProps.booking.id
        }
      });
    },
    onEventDrop({ event }) {
      return this.updateEvent(event);
    },
    fetchEvents(start, end) {},
    fetchResources() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + this.roomFetchUrl)
        .then(({ data }) => {
          data.forEach(room => {
            room.title = room.name + " / " + room.floor;
          });
          this.resources = data;
        });
    }
  },
  beforeDestroy() {
    var mainNav = document.getElementById("mainNav");
    mainNav.classList.remove("d-none");
  },
  created() {
    var mainNav = document.getElementById("mainNav");
    mainNav.classList.add("d-none");
  },
  mounted() {
    var mainNav = document.getElementById("mainNav");
    mainNav.classList.add("d-none");

    // let calendarApi = this.$refs.fullCalendar.getApi();
    // calendarApi.setOption("height", "100vh");

    this.fetchResources();
    this.$store.sidebarOpen = false;
  },
  components: {
    Loading,
    FullCalendar
  }
};
</script>

<style></style>
